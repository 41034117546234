<template>
  <div class="admin-wrapper">
    <div class="breadcrumb">
      <router-link to="/admin/calculator-builder" class="breadcrumb__parent">Inventar type</router-link>
      <svg width="6" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 5-4 5" stroke="#474746" /></svg>
      <span class="breadcrumb__first-child">{{ optionalStepNameBySlug($route.params.step) }}</span>
      <span class="breadcrumb__action" @click="onShowSubStepModal(true, '')">Tilføj sektion</span>
    </div>
    <div class="sub-step" v-for="(currentSubStep, index) in filteredSubSteps" :key="currentSubStep.id">
      <div class="header__container">
        <h2 class="header" :class="{ 'header--removing': removing(currentSubStep.id) }">{{ currentSubStep.name }}</h2>
        <div class="header__actions">
          <span class="header__action" @click="onShowSubStepModal(false, currentSubStep)">Rediger sektion</span>
          <span class="header__action" @click="onRemoveSubStep(currentSubStep.id)">Fjern sektion</span>
        </div>
        <div class="header__arrows">
          <div class="header__arrow" @click="moveDown(index)">
            <!-- prettier-ignore -->
            <svg class="arrow" width="12" height="8" viewBox="0 0 11 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
          </div>
          <div class="header__arrow" @click="moveUp(index)">
            <!-- prettier-ignore -->
            <svg class="arrow" width="12" height="8" viewBox="0 0 11 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
          </div>
        </div>
      </div>

      <div class="steps">
        <template v-if="isLoading">
          <div class="step"></div>
          <div class="step"></div>
          <div class="step"></div>
        </template>
        <Items v-else itemType="subStep" :name="currentSubStep.id" :isLoading="isLoading"></Items>
      </div>
    </div>
    <SubStepModal v-if="showSubStepModal" :subStep="subStep" :isNew="isNew" @close="showSubStepModal = false"></SubStepModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SubStepModal from '@/components/admin/SubStepModal.vue'
import Items from '@/components/admin/Items.vue'

export default {
  name: 'Step',
  data() {
    return {
      isLoading: true,
      isRemoving: false,
      removingId: false,
      showSubStepModal: false,
      subStep: '',
      isNew: false,
    }
  },
  components: {
    SubStepModal,
    Items,
  },
  methods: {
    ...mapActions(['getSubStepsByStep', 'updateSubSteps', 'getItemsByStep', 'deleteItem', 'deleteSubStep', 'getItems']),
    moveDown(i) {
      if (i !== -1 && i < this.filteredSubSteps.length - 1) {
        this.filteredSubSteps[i].index = i + 1
        this.filteredSubSteps[i + 1].index = i
        this.updateSubSteps(this.filteredSubSteps)
      }
    },
    moveUp(i) {
      if (i > 0) {
        this.filteredSubSteps[i].index = this.filteredSubSteps[i].index - 1
        this.filteredSubSteps[i - 1].index = i
        this.updateSubSteps(this.filteredSubSteps)
      }
    },
    removing(id) {
      return this.removingId == id ? true : false
    },
    onShowSubStepModal(isNew, subStep) {
      this.isNew = isNew
      const defaultSubStep = {
        name: '',
        parent: this.$route.params.step,
        index: 99,
      }

      isNew ? (this.subStep = defaultSubStep) : (this.subStep = subStep)
      this.showSubStepModal = true
    },
    async onRemoveSubStep(id) {
      this.$confirm({
        message: `Er du sikker på at du vil slette denne sektion?`,
        button: {
          yes: 'Ja',
          no: 'Nej',
        },
        callback: async confirm => {
          if (confirm) {
            this.isRemoving = true
            this.removingId = id
            await this.deleteSubStep(id).catch(() => this.deleteError())
            this.deleteSucces()
            this.isRemoving = false
          }
        },
      })
    },
    deleteSucces() {
      this.$notify({
        type: 'success',
        title: 'Succes! ',
        text:
          'Feltet er slettet <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#fff"/></svg>',
      })
    },
    deleteError() {
      this.$notify({
        type: 'error',
        title: 'Fejl!',
        text: 'Noget gik galt',
      })
    },
  },
  async mounted() {
    this.isLoading = true
    const newStep = this.adminCurrentStep != this.$route.params.step ? true : false
    if (newStep) await this.getItemsByStep(this.$route.params.step)
    this.isLoading = false
    this.$store.dispatch('setAdminCurrentStep', this.$route.params.step)
  },
  computed: {
    ...mapGetters(['adminCurrentStep', 'optionalStepNameBySlug', 'subSteps']),
    filteredSubSteps() {
      return this.subSteps.filter(subStep => subStep.parent == this.$route.params.step)
    },
  },
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  > svg {
    margin-right: 0.75rem;
  }
}
.breadcrumb__parent {
  font-size: 1.125rem;
  text-decoration: none;
  color: #999999;
  font-weight: 400;
  margin-right: 0.75rem;
}
.breadcrumb__first-child {
  color: #333;
  font-weight: 500;
}
.breadcrumb__action {
  cursor: pointer;
  color: #333;
  margin-left: auto;
  font-weight: 400;
}
.sub-step {
  transition: 0.5s;
}
.header__container {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}
.header {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
  font-weight: 500;
}
.header--removing {
  opacity: 0.5;
}
.header__actions {
  margin-left: auto;
}
.header__action {
  cursor: pointer;
  color: #333;
  font-weight: 400;
  margin-left: 1.25rem;
}
.header__arrows {
  display: flex;
  margin-left: 1.5rem;
}
.header__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  width: 16px;
  height: 16px;
  border-radius: 2rem;
  border: 1px solid #999;
  margin-left: 0.5rem;
  cursor: pointer;
  &:nth-child(2) svg {
    transform: rotateX(180deg);
  }
  &:hover {
    background-color: #f4f4f4;
  }
  &:hover > svg > path {
    stroke: #333;
  }
}
</style>
